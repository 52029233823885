import React from "react"
import Layout from "../components/layout"

const IndexPage = () => {
    return (
        <Layout>
            <div>
                <h1>Limaportalur</h1>
                <h2>Kiwanis</h2>
            </div>
        </Layout>
    )
}

export default IndexPage
